<template>
  <Loading v-if="initLoading" />
  <div class="redEnvelopes" v-else>
    <Header isNoBg>红包奖励</Header>
    <div
      style="
        padding-bottom: 40px;
        background: linear-gradient(#ffe077, #f4d055);
      "
    >
      <div class="list">
        <div class="left">
          <div class="money">¥{{ walletInfo.num || 0 }}</div>
          <div class="title">我的奖励(元)</div>
        </div>
        <div class="right">
          <div class="money">¥{{ walletInfo.balance || 0 }}</div>
          <div class="title">可转金额(元)</div>
        </div>
      </div>
      <div class="btn" @click="$router.push('/transferToBalance')">
        转出到余额
      </div>
    </div>
    <div class="block">
      <div class="product">
        <div style="font-size: 14px; font-weight: 500">转出明细</div>
        <div class="checkbox" @click="showPicker = true">
          筛选
          <i class="inverted"></i>
        </div>
      </div>
      <!-- <div class="record" ref="scroll"> -->
      <van-list
        v-if="recordList.length"
        v-model="loading"
        :finished="finished"
        class="record"
        finished-text="没有更多了"
        loading-text="数据加载中..."
        style="overflow: auto"
        @load="onLoad"
        :immediate-check="false"
      >
        <div class="recordItem" v-for="item in recordList" :key="item.id">
          <div class="left">
            <div class="title">{{ item.describe }}</div>
            <div class="subTitle">{{ item.created_at }}</div>
          </div>
          <div class="right">
            <div class="title">
              {{ item.chargeWithdraw === 0 ? '+' : '-' }}{{ item.fee || 0 }}元
            </div>
            <div class="subTitle">
              {{
                item.status === 1
                  ? item.chargeWithdraw === 1
                    ? '转出'
                    : '转入'
                  : null
              }}{{ status[item.status] }}
            </div>
          </div>
        </div>
      </van-list>
      <!-- <div v-if="isHaveMore">
        <div class="even" @click="loadNextPage">
          查看更多
          <img src="../../../../static/image/bottom.png" alt="" />
        </div>
      </div> -->
      <div class="noData" v-if="!recordList.length">
        <img src="../../../../static/image/hezi2.png" alt="" />
        <div class="noDataInfo">暂无数据</div>
      </div>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="confirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: ['全部', '转入', '转出'],
      showPicker: false,
      initLoading: true,
      recordList: [],
      query: {
        pageindex: 1,
        pagesize: 20,
        type: 0,
        token: localStorage.getItem('token'),
      },
      status: {
        '-1': '未转账',
        0: '确认中',
        1: '成功',
      },
      isHaveMore: false,
      walletInfo: {},
      finished: false,
      loading: false,
    };
  },
  mounted() {
    this.getRewardWalletList();
    this.getRewardWallet();
  },
  methods: {
    onLoad() {
      if (this.isHaveMore) {
        this.query.pageindex++;
        this.getRewardWalletList('more');
      } else {
        this.finished = true;
      }
    },
    async getRewardWallet() {
      try {
        const res = await this.$api.rewardWallet({
          token: localStorage.getItem('token'),
        });
        if (res.code === 0) {
          this.walletInfo = res.data || {};
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.initLoading = false;
      }
    },
    loadNextPage() {
      this.query.pageindex++;
      this.getRewardWalletList('more');
    },
    confirm(e) {
      this.query.pageindex = 1;
      this.finished = false;
      if (e === '转出') {
        this.query.type = 2;
      } else if (e === '转入') {
        this.query.type = 1;
      } else {
        this.query.type = 0;
      }
      this.getRewardWalletList();
      this.showPicker = false;
    },
    async getRewardWalletList(isLoadMore) {
      try {
        const res = await this.$api.rewardWalletList(this.query);
        if (res.code === 0) {
          if (isLoadMore) {
            this.recordList = this.recordList.concat(res.data || []);
          } else {
            this.recordList = res.data || [];
          }
          if (this.query.pagesize > res.data.length) {
            this.isHaveMore = false;
          } else {
            this.isHaveMore = true;
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.redEnvelopes {
  background: linear-gradient(#ffe077, #f4d055);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  .noData {
    text-align: center;
    img {
      width: 100px;
      margin-right: -10px;
    }
    .noDataInfo {
      font-size: 12px;
      color: #666;
    }
  }
  .list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    .left {
      text-align: center;
      flex: 1;
      .money {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 32px;
      }
      .title {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
      }
    }
    .right {
      text-align: center;
      flex: 1;
      .money {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 32px;
      }
      .title {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
      }
    }
  }
  .btn {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    width: 128px;
    height: 44px;
    background: #fff7db;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}
.block {
  width: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-color: #fff;
  overflow: auto;
  padding: 20px 0 10px;
  z-index: 9;
  flex: 1;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  .record {
    margin-top: 30px;
    padding: 0 15px;
    overflow: auto;
    .recordItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f8f8f8;
      padding: 15px 0;
      &:last-child {
        border: none;
      }
      .title {
        font-size: 14px;
        color: #333333;
      }
      .subTitle {
        font-size: 12px;
        color: #cecece;
      }
      .right {
        .title {
          font-weight: 500;
        }
        .subTitle {
          text-align: right;
          color: #f5d35a;
        }
      }
    }
  }
  .product {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 15px;
    right: 15px;
    top: 20px;
    img {
      width: 12px;
      height: 12px;
    }
  }
  .checkbox {
    display: flex;
    font-size: 13px;
    font-weight: 400;
    color: #666;
    i {
      display: flex;
      flex-direction: column;
      margin: 3px 0;
      width: 10px;
      height: 10px;
      margin-left: 5px;
      margin-top: 7px;
      &.just {
        &::after {
          display: block;
          content: '';
          border-top: 5px solid transparent;
          border-bottom: 5px solid #ddd;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }
        &.active {
          &.just {
            &::after {
              border-bottom: 5px solid #333;
            }
          }
        }
      }
      &.inverted {
        &::after {
          display: block;
          content: '';
          border-top: 5px solid #ddd;
          border-bottom: 5px solid transparent;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }
        &.active {
          &.inverted {
            &::after {
              border-top: 5px solid #333;
            }
          }
        }
      }
    }
  }
  .product_list {
    display: flex;
    font-size: 16px;
    line-height: 60px;
    justify-content: space-between;
    margin-right: 20px;
    .product_text {
      display: flex;
      align-items: center;
    }
    .product_num {
      margin-left: 10px;
      display: flex;
      font-size: 12px;
    }
    .num_several {
      margin-left: 5px;
      font-size: 10px;
      color: #aaa;
      &.active {
        color: #fab878;
        span {
          padding: 3px;
        }
      }
      &.unActive {
        color: #cecece;
        span {
          background: #f8f8f8;
          padding: 3px;
        }
      }
      &.lock {
        color: #009944;
        span {
          background: #deffee;
          padding: 3px;
        }
      }
    }
    .product_img {
      width: 4px;
      height: 6px;
      margin-left: 5px;
    }
  }
  .even {
    font-size: 12px;
    color: #abaab9;
    background-color: #abaab957;
    width: 70px;
    text-align: center;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-left: 15px;
    img {
      width: 6px;
      height: 4px;
    }
  }
}
</style>
